import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';

const RowDisplayWithIcons = ({ items }) => (
  <Stack direction="row" spacing={7} alignItems="center">
    {items.map(({ title, subtitle, Icon: IconComponent }) => (
      <Stack key={title} spacing={2} alignItems="center">
        <IconComponent color="primary" sx={{ fontSize: 40 }} />
        <Typography color="primary" fontWeight="bold" sx={{ fontSize: { xs: '0.7rem', sm: '1rem', md: '1.25rem' } }}>
          {title}
        </Typography>
        <Typography variant="body2" color="text.primary" sx={{ fontSize: { xs: '0.5rem', sm: '0.7rem', md: '1rem' } }}>
          {subtitle}
        </Typography>
      </Stack>
    ))}
  </Stack>
);

RowDisplayWithIcons.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      Icon: PropTypes.elementType.isRequired,
    }).isRequired,
  ).isRequired,
};

export default RowDisplayWithIcons;
